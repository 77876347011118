import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      <div className="container">
        <Link to="/ubnap" className="logo">
          <span className="logo-icon">&#x2695;</span>
          <span className="logo-text">UBNAP</span>
        </Link>
        <button className="menu-toggle" onClick={toggleMenu}>
          ☰
        </button>
        <nav className={isMenuOpen ? 'open' : ''}>
          <ul>
            <li><Link to="/ubnap" onClick={toggleMenu}>Home</Link></li>
            <li><Link to="/ubnap/products" onClick={toggleMenu}>Products</Link></li>
            <li><Link to="/ubnap/contact" onClick={toggleMenu}>Contact</Link></li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;